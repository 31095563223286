const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
let { API_ROOT, DESTINATION } = publicRuntimeConfig;
const querystring = require("querystring");

//API_ROOT = 'http://localhost:8085';

const {
  get,
  put,
  rdelete,
  post,
  getCallback,
  putCallback,
  deleteCallback,
  postCallback,
  patchCallback,
  rhead,
  getAsync,
} = require("./methods");
const default_language = "en";
var base64 = require("base-64");

function getFilters(qs) {
  if (qs.filters) {
    if (!isArray(qs.filters)) {
      qs.filters = [qs.filters];
    }
  }
  let filters = (qs.filters || []).join(",");
  filters = filters.replace(",,", ",");
  if (filters.indexOf(",") === 0) {
    filters = filters.substring(1);
  }
  if (filters.lastIndexOf(",") === filters.length - 1) {
    filters = filters.substring(0, filters.lastIndexOf(","));
  }
  return filters;
}
function isArray(obj) {
  return Object.prototype.toString.call(obj) === "[object Array]";
}

function getRealDestination(destination) {
  if (DESTINATION !== "") {
    return DESTINATION;
  }
  return destination;
}

exports.singin = (user, password) => {
  return post(`${API_ROOT}/v1/user/login`, {
    data: { user, password },
  });
};

exports.registerFacebook = (id, name, mail, picture, callback) => {
  return postCallback(
    `${API_ROOT}/v1/user/facebook`,
    {
      data: { id, name, mail, picture },
    },
    callback
  );
};

exports.registerApple = (id, name, mail) => {
  return post(`${API_ROOT}/v1/user/apple`, {
    data: { id, name, mail },
  });
};

exports.getUserData = (token) => {
  return get(`${API_ROOT}/v1/user`, {
    token,
  });
};

exports.getFavorites = (lang = default_language, token) => {
  return get(`${API_ROOT}/favourite/user/${lang}`, {
    token,
  });
};

exports.addFavorite = (token, id, callback) => {
  return postCallback(
    `${API_ROOT}/v1/favourite/${id}`,
    {
      webAuthorization: token,
    },
    callback
  );
};

exports.removeFavorite = (token, id, callback) => {
  return deleteCallback(
    `${API_ROOT}/v1/favourite/${id}`,
    {
      webAuthorization: token,
    },
    callback
  );
};

exports.getDestinations = (lang = default_language) => {
  return get(`${API_ROOT}/v1/destination/${lang}`);
};

exports.getDestinationDetails = (rawId = "madrid", lang = default_language) => {
  return get(`${API_ROOT}/v1/destination/${getRealDestination(rawId)}/${lang}`);
};

exports.getDestinationDetailsPreview = (
  rawId = "madrid",
  lang = default_language
) => {
  return get(
    `${API_ROOT}/v1/destination/preview/${getRealDestination(rawId)}/${lang}`
  );
};

exports.getTerms = (lang = default_language) => {
  return get(`${API_ROOT}/terms/${lang}`);
};

exports.getPolicy = (lang = default_language) => {
  return get(`${API_ROOT}/policy/${lang}`);
};

exports.getCookie = (lang = default_language) => {
  return get(`${API_ROOT}/cookie/${lang}`);
};

exports.getLegalWarning = (lang = default_language) => {
  return get(`${API_ROOT}/legal_warning/${lang}`);
};

exports.getCategoryDetails = (
  rawId,
  destination = "madrid",
  lang = default_language,
  qs = {}
) => {
  const filters = getFilters(qs);
  return get(
    `${API_ROOT}/v1/category/${rawId}/${getRealDestination(
      destination
    )}/${lang}?tags=&filters=${filters}`
  );
};

exports.getCategoryDetailsCallback = (
  rawId,
  destination = "madrid",
  lang = default_language,
  qs = {},
  callback
) => {
  const filters = getFilters(qs);
  return getCallback(
    `${API_ROOT}/v1/category/${rawId}/${getRealDestination(
      destination
    )}/${lang}?tags=&filters=${filters}`,
    {},
    callback
  );
};

exports.getSubcategoryDetails = (
  subCategoryRawId,
  destination = "madrid",
  lang = default_language,
  qs = {}
) => {
  const filters = getFilters(qs);
  return get(
    `${API_ROOT}/v1/subcategory/${subCategoryRawId}/${getRealDestination(
      destination
    )}/${lang}?tags=&filters=${filters}`
  );
};

exports.getSubcategoryDetailsCallback = (
  subCategoryRawId,
  destination = "madrid",
  lang = default_language,
  qs = {},
  callback
) => {
  const filters = getFilters(qs);
  return getCallback(
    `${API_ROOT}/v1/subcategory/${subCategoryRawId}/${getRealDestination(
      destination
    )}/${lang}?tags=&filters=${filters}`,
    {},
    callback
  );
};

exports.getExperienceDetails = (businessRawId, lang = default_language) => {
  return get(`${API_ROOT}/business/${businessRawId}/detail/${lang}`).then(
    (data) => {
      const categoryId = data.category && data.category.split(",")[0];
      return get(
        `${API_ROOT}/business/${businessRawId}/near/${categoryId}/${lang}`
      ).then((near) => ({ data, near }));
    }
  );
};

exports.getExperienceDetailsPreview = (
  businessRawId,
  lang = default_language
) => {
  return get(`${API_ROOT}/business/${businessRawId}/preview/${lang}`).then(
    (data) => {
      const categoryId = data.category && data.category.split(",")[0];
      return get(
        `${API_ROOT}/business/${businessRawId}/near/${categoryId}/${lang}`
      ).then((near) => ({ data, near }));
    }
  );
};

exports.getSearchResults = (
  query = {},
  destination = "madrid",
  lang = default_language
) => {
  if (query.filters) {
    query.filters = query.filters.map((c) => c.toString()).join(",");
  }
  const qs = querystring.stringify(query);
  return get(
    `${API_ROOT}/v1/search/${getRealDestination(destination)}/${lang}?${qs}`
  );
};

exports.getSearchFilters = (destination, locale = default_language) => {
  return get(
    `${API_ROOT}/v1/category/${getRealDestination(destination)}/${locale}`
  );
};

exports.getCalendarFilters = (destination, locale = default_language) => {
  return get(
    `${API_ROOT}/v1/calendar/filter/${getRealDestination(
      destination
    )}/${locale}`
  );
};

exports.recover = (mail, city, locale, callback) => {
  const options = {
    data: {
      mail: mail,
      locale: locale,
      city: city,
    },
  };
  return patchCallback(`${API_ROOT}/v1/user`, options, callback);
};

exports.old_singin = (mail, password, callback) => {
  const options = {
    data: {
      user: mail,
      password: password,
    },
  };
  return postCallback(`${API_ROOT}/v1/user/login`, options, callback);
};

exports.userDetail = (token) => {
  const options = {
    token: token,
  };
  return post(`${API_ROOT}/user/detail`, options);
};

exports.getRequestLocale = (req, asPath) => {
  if (req) {
    return req.locale;
  }
  return asPath.split("/").filter((c) => !!c)[0] || "en";
};

exports.addCategoryOfInterest = (token, id, callback) => {
  return postCallback(
    `${API_ROOT}/v1/category/of/interest/${id}`,
    {
      webAuthorization: token,
    },
    callback
  );
};

exports.deleteCategoryOfInterest = (token, id, callback) => {
  return deleteCallback(
    `${API_ROOT}/v1/category/of/interest/${id}`,
    {
      webAuthorization: token,
    },
    callback
  );
};

exports.saveUser = (token, oldPassword, password, city, language, callback) => {
  return putCallback(
    `${API_ROOT}/v1/user`,
    {
      data: {
        oldPassword,
        password,
        city,
        language,
      },
      webAuthorization: token,
    },
    callback
  );
};

exports.register = (data, callback) => {
  return postCallback(
    `${API_ROOT}/v1/user`,
    {
      data: data,
    },
    callback
  );
};

exports.saveAvatar = (token, image, callback) => {
  return putCallback(
    `${API_ROOT}/v1/user/avatar`,
    {
      data: {
        image: image.substring(image.indexOf("base64,") + "base64.".length),
      },
      webAuthorization: token,
    },
    callback
  );
};

exports.checkRecoverToken = (token) => {
  return rhead(`${API_ROOT}/v2/recover/${token}`)
    .then((data) => {
      return 1;
    })
    .catch((e) => {
      return 0;
    });
};

exports.finishRecover = (password, token, callback) => {
  const options = {
    data: {
      password: password,
      token: token,
    },
  };
  return putCallback(`${API_ROOT}/v2/recover`, options, callback);
};

exports.getCategoriesPlan = (destination, locale = default_language) => {
  return get(
    `${API_ROOT}/v2/play/my/trip/category/${getRealDestination(
      destination
    )}/${locale}`
  );
};

exports.searchPlan = (
  destination,
  categories,
  startDate,
  endDate,
  relationships,
  perfectFor,
  callback,
  locale = default_language
) => {
  const options = {
    data: {
      destination: getRealDestination(destination),
      categories: categories,
      startDate: startDate,
      endDate: endDate,
      relationships: relationships !== undefined ? relationships.id : undefined,
      perfectFor: perfectFor !== undefined ? perfectFor.id : undefined,
      language: locale,
    },
    returnData: true,
  };
  return postCallback(`${API_ROOT}/v2/play/my/trip`, options, callback);
};

exports.getPlan = (token, locale = default_language) => {
  return get(`${API_ROOT}/v2/play/my/trip/${token}/${locale}`);
};

exports.getPlanCallback = (token, locale = default_language, callback) => {
  return getCallback(
    `${API_ROOT}/v2/play/my/trip/${token}/${locale}`,
    {},
    callback
  );
};

exports.changePlan = (token, id, locale = default_language, callback) => {
  return putCallback(
    `${API_ROOT}/v2/play/my/trip/${token}/${id}/${locale}`,
    undefined,
    callback
  );
};

exports.deleteRecommendedPlan = (token, id, callback) => {
  return deleteCallback(
    `${API_ROOT}/v2/play/my/trip/${token}/${id}`,
    undefined,
    callback
  );
};

exports.finishPlan = (token, callback) => {
  return postCallback(
    `${API_ROOT}/v2/play/my/trip/${token}`,
    undefined,
    callback
  );
};

exports.finishUserPlan = (token, userToken, callback) => {
  return postCallback(
    `${API_ROOT}/v2/play/my/trip/user/${token}`,
    { webAuthorization: userToken },
    callback
  );
};

exports.getFinishPlan = (token, locale = default_language) => {
  return get(`${API_ROOT}/v2/play/my/trip/finish/${token}/${locale}`);
};

exports.getFinishPlanCallback = (token, locale = default_language, callback) => {
  return getCallback(
    `${API_ROOT}/v2/play/my/trip/finish/${token}/${locale}`,
    {},
    callback
  );
};

exports.createMarketCart = (data) => {
  return post(`${API_ROOT}/v1/market/list`, {
    data,
  });
};

exports.createThirdPartyMarketCart = (data) => {
  return post(`${API_ROOT}/v1/third-party/market/list`, {
    data,
  });
};

exports.getMarketCart = (locale, id) => {
  return get(`${API_ROOT}/v1/market/${locale}?uuid=${id}`);
};

exports.getThirdPartyMarketCart = (locale, company, id) => {
  return get(`${API_ROOT}/v1/third-party/market/${locale}/${company}?uuid=${id}`);
};

exports.finishCartCheckout = (data) => {
  return post(`${API_ROOT}/v1/market/finish`, {
    data,
  });
};

exports.finishThirdPartyCartCheckout = (data) => {
  return post(`${API_ROOT}/v1/thrid-party/market/finish`, {
    data,
  });
};

exports.finishTotemCartCheckout = (data) => {
  return post(`${API_ROOT}/v1/market/finish/totem`, {
    data,
  });
};

exports.updateCartCheckout = (data) => {
  return put(`${API_ROOT}/v1/market`, {
    data,
  });
};

exports.updateThirdPartyCartCheckout = (data) => {
  return put(`${API_ROOT}/v1/third-party/market`, {
    data,
  });
};

exports.updateCartCheckoutWithUser = (data, user) => {
  return put(`${API_ROOT}/v1/market/with/user`, {
    token: user.token,
    data,
  });
};

exports.updateCartCheckoutTotem = (data) => {
  return put(`${API_ROOT}/v1/market/totem`, {
    data,
  });
};

exports.getCountries = () => {
  return get(`${API_ROOT}/v1/market/country`);
};

exports.getMyTrips = (lang = default_language, token) => {
  return get(`${API_ROOT}/v1/user/play/my/trip/${lang}`, {
    token,
  });
};

exports.getRecommendedFavorites = (
  lang = default_language,
  token,
  destination,
  category,
  filterText
) => {
  return get(
    `${API_ROOT}/v2/favourite/search?destinationRawId=${getRealDestination(
      destination
    )}&locale=${lang}&categoryRawId=${category}&text=${filterText}`,
    {
      token,
    }
  );
};

exports.getOrders = (lang = default_language, token) => {
  return get(`${API_ROOT}/v1/user/market/${lang}`, {
    token,
  });
};

exports.getProducts = (lang = default_language, id, startDay, endDay) => {
  return get(`${API_ROOT}/v1/product/${id}/stock/${lang}?startDay=${startDay}&endDay=${endDay}`);
};

exports.getAsyncProducts = (lang = default_language, id, startDay, endDay) => {
  return getAsync(`${API_ROOT}/v1/product/${id}/stock/${lang}?startDay=${startDay}&endDay=${endDay}`);
};

exports.registerContacts = (lang = default_language, type, email, userType, callback) => {
  postCallback(`${API_ROOT}/v2/contact`, {
    data: { email, type, userType, language: lang },
  }, callback);
}

exports.getTotem = (rawId, lang = default_language) => {
  return get(`${API_ROOT}/v2/totem/${rawId}/${lang}`);
}

exports.getTotemWithCallback = (rawId, lang = default_language, callback) => {
  getCallback(`${API_ROOT}/v2/totem/${rawId}/${lang}`, {}, callback);
}

exports.getTotemBusiness = (rawId, lang = default_language) => {
  return get(`${API_ROOT}/v2/totem/${rawId}/${lang}/business`);
}

exports.saveContactRecommended = (businessId, destinationRawId, name, email, phone, numPerson, lang = default_language, callback) => {
  postCallback(`${API_ROOT}/v2/contact/recommended`, {
    data: {
      businessId,
      language: lang,
      destinationRawId,
      name,
      email,
      phone,
      numPerson,
    }
  }, callback);
}

import Router from 'next/router'
import nextCookie from 'next-cookies'
import cookies from 'js-cookie'
import { getUserData } from '../api'

function login({ token, lang, type }) {
    cookies.set("token", token, { expires: 1 });
    cookies.set("loginType", type, { expires: 1 });
    Router.push(!!lang ? `/${lang}` : "/")
}

function noAppleLogin ({lang, destination}){
    Router.push(`/${lang}/${destination}/signin`)
}

export const block = (ctx) => {
    const { token } = nextCookie(ctx)
    if (ctx.req && token) {
        ctx.res.writeHead(302, { Location: '/' })
        ctx.res.end()
        return
    }

    if (token) {
        Router.push('/')
    }
}

export const getUserInfo = async (ctx) => {
    const { token, loginType } = nextCookie(ctx)
    if (token) {
        try {
            const user = await getUserData(token)
            return { ...user, token, is_logged: !!user, loginType }
        } catch (error) {
            return { is_logged: false, token: null, loginType: null }
        }
    }
    return { is_logged: false, token: null, loginType: null }
}

export const logout = (lang) => {
    cookies.remove("token");
    cookies.remove("loginType");
    window.localStorage.setItem("logout", Date.now())
    Router.push(!!lang ? `/${lang}` : "/")
}

export const isLogged = (ctx) => {
    const { token } = nextCookie(ctx)
    return !!token
}

function getToken(ctx) {
    const { token } = nextCookie(ctx)
    return token
}

function auth(ctx) {
    const { token } = nextCookie(ctx)
    (`auth: ${token}`)
    if (ctx.req && !token) {
        ctx.res.writeHead(302, { Location: '/login' })
        ctx.res.end()
        return
    }

    if (!token) {
        Router.push('/login')
    }

    return token
}

const getDisplayName = Component => Component.displayName || Component.name || 'Component'


export { login }
export { noAppleLogin }
export { auth }
export { getToken }

export const withAuthSync = WrappedComponent =>
    class extends Component {
        static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`
        static async getInitialProps(ctx) {
            const token = auth(ctx)
            const componentProps =
                WrappedComponent.getInitialProps &&
                (await WrappedComponent.getInitialProps(ctx))

            return { ...componentProps, token }
        }
        constructor(props) {
            super(props)
            this.syncLogout = this.syncLogout.bind(this)
        }
        componentDidMount() {
            window.addEventListener('storage', this.syncLogout)
        }

        componentWillUnmount() {
            window.removeEventListener('storage', this.syncLogout)
            window.localStorage.removeItem('logout')
        }
        syncLogout(event) {
            if (event.key === 'logout') Router.push('/login')
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }

const fetch = require('isomorphic-unfetch')

const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
}

const corsHeaders = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, X-Auth-Token, Accept, Authorization, webauthorization',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, HEAD, PATCH, OPTIONS',
}

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const request = (method = 'GET') => {
    return (url, options = {}) => {
        let params = {
            method,
            headers: { ...defaultHeaders }
        }
        if (method != 'GET' && options.data) params.body = JSON.stringify(options.data);
        if (options.token) params.headers['webauthorization'] = `Bearer ${options.token}`
        if (options.cors) {
            params.headers = {
                ...params.headers,
                ...corsHeaders
            }
        }
        const nowPreFecth = new Date();
        return fetch(url, params)
            .then(r => {
                const nowPostFecth = new Date();
                const seconds = (nowPreFecth.getTime() - nowPostFecth.getTime()) / 1000;
                if (!r.ok) {
                    throw new Error("ok:false");
                }
                if(method === 'HEAD'){
                    return ''
                }
                return r.json()
            })
            .then(r => {
                if (r.error) {
                    throw new Error(r.error.message);
                }
                return r
            });
    }
}

const requestAsync = (method = 'GET') => {
    return async (url, options = {}) => {
        let params = {
            method,
            headers: { ...defaultHeaders }
        }
        if (method != 'GET' && options.data) params.body = JSON.stringify(options.data);
        if (options.token) params.headers['webauthorization'] = `Bearer ${options.token}`
        if (options.cors) {
            params.headers = {
                ...params.headers,
                ...corsHeaders
            }
        }
        const nowPreFecth = new Date();
        const response = await fetch(url, params);
        return await response.json()
    }
}

const multipart = (url, options) => {
    return new Promise(function (resolve, reject) {

        let xhr = new XMLHttpRequest();
        let formdata = new FormData();

        options.data.forEach((item) => {
            if (Array.isArray(item.value)) {
                item.value.forEach((subitem) => {
                    formdata.append(item.key, subitem.value);
                })
            } else {
                formdata.append(item.key, item.value)
            }
        })

        xhr.open(options.method || "POST", url);
        if (options.token) xhr.setRequestHeader("Authorization", `Baerer ${options.token}`);
        xhr.upload.onprogress = function (e) {
            if (e.lengthComputable) {
                var percentage = (e.loaded / e.total) * 100;
                if (options.callback) {
                    options.callback(percentage)
                }
            }
        };

        xhr.onload = function () {
            let response = JSON.parse(xhr.response)
            if (this.status >= 200 && this.status < 300) {
                resolve(response);
            }
            else if (this.status === 401) {
                if (Context.getInstance().store) {
                    Context.getInstance().store.dispatch(reset())
                }
                reject(new Error(response.error.message));
            }
            else {
                reject(new Error(response.error.message));
            }
        };
        xhr.onerror = function (err) {
            reject(new Error(xhr.statusText));
        };
        xhr.send(formdata);
    });

}

const requestCallback = (method = 'GET') => {
    return (url, options = {}, callback) => {
        let params = {
            method,
            headers: { ...defaultHeaders }
        }
        if (method != 'GET' && options.data) params.body = JSON.stringify(options.data);
        if (options.webAuthorization) params.headers.webauthorization = `Bearer ${options.webAuthorization}`
        if (options.token) params.headers.Authorization = `Baerer ${options.token}`
        if (options.basic) params.headers.Authorization = `Basic ${options.basic}`;
        if (options.cors) {
            params.headers = {
                ...params.headers,
                ...corsHeaders
            }
        }
        const nowPreFecth = new Date();
        return fetch(url, params)
            .then(r => {
                const nowPostFecth = new Date();
                const seconds = (nowPreFecth.getTime() - nowPostFecth.getTime()) / 1000;
                if (!r.ok) {
                    throw new Error("ok:false");
                }
                if(r.bodyUsed){
                    return r.json()
                }else if(method === 'GET'){
                    return r.clone().json()
                }else if(options.returnData){
                    return r.clone().json()
                }
                return {};
            })
            .then(r => {
                if (r.error)
                    throw new Error(r.error.message);
                if (callback !== undefined) {
                    callback(r);
                }
                return r
            })
    }
}

module.exports = {
    get: request("GET"),
    put: request("PUT"),
    post: request("POST"),
    rdelete: request("DELETE"),
    rhead: request("HEAD"),
    multipart,
    getCallback: requestCallback("GET"),
    putCallback: requestCallback("PUT"),
    postCallback: requestCallback("POST"),
    deleteCallback: requestCallback("DELETE"),
    patchCallback: requestCallback("PATCH"),
    getAsync: requestAsync("GET")
}
